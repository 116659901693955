/**
 * @desc 人脸图库
 * @author wwj
 * @modify tlzj
 */

/**
 * @desc state 参数
 * @param {Array} list 列表数据
 * @param {Boolean} loading 加载状态
 * @param {String} size 图片查看状态  default: normal normal(默认) small(小)  large(大)
 * @param {Boolean} isSearch 是否是以图搜图
 * @param {Array} checkedIds 生成轨迹选中的id
 * @param {Array} this.searchType 查询类型 0: 以图搜图 1： 上传图片进行搜图 2 特征值搜图
 * @param {Boolean} this.initTime 初始进入页面时先出现loading,不出现noData组件
 * @param {Object} this.urlParams 地址栏参数
 * @param {String} frameUrl 原始大图  没有用小图代替
 * @param {Array} rects 结构化信息数组
 * @param {String} url 框选Url 左上角小图
 */

/**
 * tips:
 * 1.上传图片搜图--上传图片获取url/base64 ---> 根据url/base64获取特征值 ---> 根据特征值获取列表
 * 2.关联搜图--使用id获取特征值
 * 3.特征值搜图
 */

import React from 'react';
import { message } from 'antd';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import Search from './search';
import { withRouter } from 'react-router-dom';

const Wrapper = Loader.loadBusinessComponent('BaseLibComponents', 'Wrapper');
const TitleOptions = Loader.loadBusinessComponent('BaseLibComponents', 'TitleOptions');
const RightHeader = Loader.loadBusinessComponent('BaseLibComponents', 'RightHeader');
const SearchMapModal = Loader.loadBusinessComponent('BaseLibComponents', 'SearchMapModal');
const Content = Loader.loadBusinessComponent('ResourceComponents', 'Content');

const structurError = '提取图片特征失败，请重新上传图片！';
const Loading = Loader.Loading;


@withRouter
@Decorator.withEntryLog()
@Decorator.businessProvider('face', 'tab', 'user')
@observer
@Decorator.withTab
class FaceLibrary extends React.Component {
  constructor(props) {
    super(props);
    this.initTime = true;
    this.urlParams = Utils.queryFormat(props.location.search);
    this.indexDBResult = {};
    this.state = {
      list: [],
      checkedIds: [],
      loading: true,
      size: props.user.userHabit.faceModel || 'normal',
      key: Math.random(),
      cKey: Math.random(),
      modalkey: Math.random(),
      imgVisible: false, // 框选模态框
      url: '',
      frameUrl: '', // 模态框Url
      rects: [], //结构化信息
      aidImageList: [], // 右侧aid列表
    };
  }
  componentDidMount() {
    this.suffix = this.props.location.pathname.split('/')[1];
    this.initUrlOptions();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const faceModel = nextProps.user.userHabit.faceModel
    if(faceModel && faceModel !== prevState.size && nextProps.storeId === BaseStore.tab.currentId){
      return {size:nextProps.user.userHabit.faceModel}
    }
    return null
  }

  /**
   * @desc 判断是否是上传图片搜图
   */
  getPictureBySearch = () => {
    return parseInt(this.urlParams.searchType, 10) === 1;
  };

  /**
   * @desc 进行图片结构化
   */
  getFearture = url => {
    let isBase64 = false;
    if (url && url.indexOf('data:image/png;base64') >= 0) {
      url = url.split(',')[1];
      isBase64 = true;
    }
    return Service.face.getFeature({
      [isBase64 ? 'base64' : 'url']: url
    });
  };

  /**
   * @desc 根据url获取查询条件
   */
  initUrlOptions = async () => {
    const { searchData } = this.props.face;
    const options = this.urlParams,
      searchType = this.urlParams.searchType;
    if (options.id) {
      LM_DB.get('parameter', options.id).then(result => {
        // 过滤掉其他类型的结构化信息
        if (Array.isArray(result.rects)) {
          result.rects = result.rects.filter(v => v.type === 'face');
        }
        this.indexDBResult = result;
        if (result && options.isSearch) {
          // 以图搜图--上传图片获取url/base64-根据url/base64获取特征值-根据特征值获取列表
          // 报错小图 大图 和特征数组
          this.setState({
            url: result.url,
            frameUrl: result.frameUrl,
            rects: result.rects
          });
          if (this.getPictureBySearch()) {
            // 根据url获取特征值
            this.initGetFearture(result);
          } else {
            if (searchType === '0') {
              /**根据id获取详情 */
              this.getDataDetail().then(res => {
                /**设置显示url */
                const rects = [
                  {
                    feature: res.data.faceFeature,
                    type: 'face',
                    value: res.data.faceRect
                  }
                ];
                this.setState({ url: res.data.faceUrl, key: Math.random(), frameUrl: res.data.sceneUrl, rects });
                this.mergeSearchData({
                  ...Utils.getTimerArea(searchData.timerTabsActive),
                  ...result.searchData,
                  feature: res.data.faceFeature,
                  imgId: result.imgId,
                  id: result.smId
                });
              });
            }
            if (searchType === '2') {
              // 强行兼容无特征值强行特征值搜索
              if (result.feature) {
                const option = Object.assign({}, searchData, { feature: result.feature, ...result.searchData, imgId: result.imgId, id: result.smId });
                this.mergeSearchData(option, true, true);
              } else {
                this.initGetFearture(result);
                const id = this.urlParams.searchType === '0' ? Utils.uuid() : this.urlParams.id;
                const urlParamsObj = Object.assign({}, this.urlParams, { id, searchType: 1 });
                this.urlParams = urlParamsObj;
                const indexDBObj = {
                  id,
                  searchData: toJS(this.props.face.searchData),
                  url: result.url,
                  frameUrl: result.frameUrl
                };
                this.changeIndexDBData(indexDBObj, urlParamsObj);
              }
            }
          }
        } else {
          if (result) {
            this.mergeSearchData({
              ...Utils.getTimerArea(searchData.timerTabsActive),
              ...result.searchData,
            });
          } else {
            // 未取到indexDB数据
            this.mergeSearchData(Utils.getTimerArea(searchData.timerTabsActive));
          }
        }
      });
    } else {
      this.mergeSearchData(Utils.getTimerArea(searchData.timerTabsActive));
    }
  };

  initGetFearture = result => {
    const { searchData } = this.props.face;
    this.getFearture(result.url)
      .then(res => {
        const list = (res.data && res.data.list) || [];
        const arr = list.map(v => {
          return {
            feature: v.feature,
            type: 'face',
            value: `${v.rect.left},${v.rect.top},${v.rect.width},${v.rect.height}`
          };
        });
        if (list.length > 1) {
          this.setState({
            rects: arr,
            imgVisible: true,
            key: Math.random()
          });
        } else {
          this.setState({
            rects: arr,
            key: Math.random()
          });
          this.mergeSearchData({
            ...Utils.getTimerArea(searchData.timerTabsActive),
            ...result.searchData,
            feature: result.feature,
            imgId: result.imgId,
            id: result.smId
          });
        }
      })
      .catch(err => {
        this.initTime = false;
        this.mergeSearchData({
          ...Utils.getTimerArea(searchData.timerTabsActive),
          ...result.searchData,
          feature: '',
          imgId: result.imgId
        });
        this.setState({
          loading: false,
          imgVisible: false,
          key: Math.random()
        });
      });
  };

  /**
   * @desc 根据id获取详情
   */
  getDataDetail = () => {
    return Service.face.faces({ id: this.urlParams.id }).then(res => {
      this.setState({ data: res.data });
      return res;
    });
  };

  /**
   * @desc 以图搜图查询判断-上传图片搜图和关联搜图处理
   */
  handleOptionsByType = options => {
    const { searchData } = this.props.face;
    const searchType = this.urlParams.searchType;
    options.offset = searchData.offset;
    options.feature = searchData.feature;
    if (searchType === '0') {
      options.id = this.urlParams.id;
    }
    options.score = searchData.score;
    return options;
  };


    // 新版图库修改
    queryMvids = (searchData) => {
      return Service.face.queryMvids(searchData);
    }

  /**
   * @desc 查询
   */
  search = (isLoadMore = false) => {
    const { searchData } = this.props.face;
    let options = Utils.faceOptions(searchData);
    const { changeUrl } = this.state;
    /**列表查询 */
    !isLoadMore && this.setState({ loading: true });
    if (this.urlParams.isSearch) {
      if (this.getPictureBySearch() && !searchData.feature) {
        this.initTime = false;
        this.setState({ loading: false, list: [] });
        return message.warning(structurError);
      }
      this.queryMvids({feature: searchData.feature}).then(res => {
        const list = res.data.list || [];
        this.setState({ aidImageList: list });
        console.log(toJS(searchData.aids));
        if(!searchData.aids && !!list.length) {
          searchData.aids = [list[0].aid];
        }
        // if(changeUrl) {
        //   searchData.aids = [list[0].aid];
        // }
        // if(searchData.aids) {
        //   searchData.feature = undefined;
        // }
        let searchDatas = Object.assign({}, searchData);
        this.mergeSearchData(searchDatas, false);
        let options = Utils.faceOptions(searchDatas);
        this.handleOptionsByType(options);
        // 日志记录
        const logData = {
          url: this.state.url,
          searchType: this.getPictureBySearch() ? '上传' : '图库'
        };
        return Service.face
          .queryFacesByFeature(options, logData)
          .then(results => this.handleResult(results, isLoadMore))
          .catch(err => {
            this.initTime = false;
            message.warning(err.data.message);
            this.setState({ loading: false });
          });
      })
    } else {
      // 人脸图库列表
      return Service.face
        .queryFaces(options)
        .then(results => this.handleResult(results, isLoadMore))
        .catch(err => {
          this.initTime = false;
          this.setState({ loading: false });
          return Promise.reject(err);
        });
    }
  };

  /**
   * @desc 查询列表成功之后的回调
   * @param {Object} results 请求到的数据
   * @param {Boolean} isLoadMore 加载下一页
   */
  handleResult = (results, isLoadMore) => {
    let dataList = results.data.list || [];
    this.initTime = false;
    let list = [];
    if (isLoadMore) {
      list = this.state.list;
    }
    list = list.concat(dataList);
    this.setState({
      list,
      loading: false,
      cKey: !isLoadMore ? Math.random() : this.state.cKey,
      checkedIds: !isLoadMore ? [] : this.state.checkedIds
    });
    return dataList.length;
  };

  /**
   * @desc 修改查询条件
   * @param {Object} options 查询条件
   * @param {Boolean} needSearch 是否进行查询, 默认查询
   */
  mergeSearchData = (options, needSearch = true, needReplace = false) => {
    const { frameUrl, rects } = this.state;
    if (!options.minId) {
      options.minId = '';
    }
    return this.props.face.mergeSearchData(options).then(() => {
      if (needSearch) {
        this.search();
        if (needReplace) {
          // 对应三种情况--1.普通列表 2.以图搜图 3.上传图片
          let id = this.urlParams.id;
          if (!this.urlParams.isSearch) {
            id = Utils.uuid();
          }
          let pageData = Object.assign({}, this.urlParams, { id }); // 跳转url参数
          let indexDBObj = null; // indexDB存储数据
          delete this.indexDBResult.expireTime;
          delete this.indexDBResult.userId;
          delete this.indexDBResult.time;
          indexDBObj = Object.assign({}, this.indexDBResult, { searchData: toJS(this.props.face.searchData), id, frameUrl, rects });
          this.changeIndexDBData(indexDBObj, pageData);
        }
      }
    });
  };

  /**
   * @desc 缓存数据，更新url
   * @parma {object} indexDBObj 存入indexDB的数据
   * @parma {object} urlParams url参数修改
   */
  changeIndexDBData = async (indexDBObj, urlParamsObj) => {
    const { tab, location } = this.props;
    this.urlParams = urlParamsObj;
    this.indexDBResult = indexDBObj;
    await LM_DB.add('parameter', indexDBObj);
    tab.goPage({
      moduleName: 'faceLibraryNew',
      location: location,
      isUpdate: true,
      data: urlParamsObj,
      action: 'replace'
    });
  };

  /**
   * @desc 刷新
   */
  Refresh = () => {
    // 自定义时间时，时间不变
    let timerTabsActive = this.props.face.searchData.timerTabsActive;
    timerTabsActive === 2 ? this.mergeSearchData({}) : this.mergeSearchData(Utils.getTimerArea(timerTabsActive));
  };

  /**
   * @desc 重置
   */
  reset = () => {
    this.state.url && this.setState({ url: '' });
    this.initPageData();
  };

  /**
   * @desc 勾选
   */
  onChecked = checkedIds => {
    const infiniteRefCurrent = this.refContent.infiniteRef.current;
    this.setState({ checkedIds }, () => infiniteRefCurrent && infiniteRefCurrent.forceUpdateGrid());
  };

  /**
   * @desc 滚动加载
   */
  loadMore = () => {
    const { list } = this.state;
    let { offset, limit } = this.props.face.searchData;
    let searchDataObj = this.urlParams.isSearch
      ? {
          offset: offset + limit // 以图搜图
        }
      : {
          minId: list[list.length - 1].id // 图库列表
        };
    return this.mergeSearchData(searchDataObj, false).then(() => this.search(true));
  };

  /**
   * @desc 切换图片显示大小
   */
  changesize = size => {
    const { user } = this.props;
    this.setState({ size, cKey: Math.random() });
    user.setUserHabit({faceModel: size});
  };

  /**
   * @desc 以图搜图切换图片(包含截图搜索)
   * @param {String} url base64
   */
  changeUrl = async url => {
    // url改变后切换为上传图片搜图类型
    await this.setState({ url, aidImageList: [] });
    await this.mergeSearchData({ aids: undefined }, false);
    if (!url) {
      // 删除图片操作 - 重置所有查询条件 - 重置url
      this.initPageData();
    } else {
      // 截图查询  1.以图搜图截图-----更新id   2.上传图片的情况下截图-----更新url
      this.getFearture(url)
        .then(res => {
          const len = res.data.list.length;
          if (!len) {
            this.setState({ loading: false, list: [] });
            return message.warning(structurError);
          }
          if (len === 1) {
            this.changeUrlHandel(res.data.list[0].feature, url);
            this.handFrame(
              url,
              [],
              true,
              {
                feature: res.data.list[0].feature
              },
              url
            );
          }
          if (len > 1) {
            const arr = res.data.list.map(v => {
              return {
                feature: v.feature,
                type: 'face',
                value: `${v.rect.left},${v.rect.top},${v.rect.width},${v.rect.height}`
              };
            });
            this.handFrame(url, arr, true, undefined, url);
          }
        })
        .catch(err => {
          this.setState({ loading: false, list: [] });
          this.changeUrlHandel('', url, false);
          return message.warning(err.data.message || structurError);
        });
    }
  };

  /**
   * @desc 截图搜图情况下查询特征值后的回调（查询成功和失败都要重置参数，避免特征值查询失败后再修改条件查出数据）
   * @param {string} feature 查询到的特征值
   * @param {String} url 截图后的base64
   * @param {Boolean} needSearch 是否需要更新列表
   */
  changeUrlHandel = async (feature, url, needSearch = true) => {
    const { face } = this.props;
    await face.mergeSearchData({ feature: feature || '', imgId: undefined, id: undefined });
    const id = this.urlParams.searchType === '0' ? Utils.uuid() : this.urlParams.id;
    const urlParamsObj = Object.assign({}, this.urlParams, { id, searchType: 1 });
    this.urlParams = urlParamsObj;
    needSearch && this.search();
    const indexDBObj = { id, searchData: toJS(this.props.face.searchData), url };
    this.changeIndexDBData(indexDBObj, urlParamsObj);
  };

  /**
   * @desc 重置所有查询条件 - 重置url
   */
  initPageData = async () => {
    const { face, tab, location } = this.props;
    this.urlParams = {};
    face.initData();
    await face.mergeSearchData(Utils.getTimerArea(face.searchData.timerTabsActive));
    this.search();
    tab.goPage({
      moduleName: 'faceLibraryNew',
      location: location,
      isUpdate: true,
      data: {},
      action: 'replace'
    });
  };

  /**
   * @desc 人脸搜图详情跳转
   */
  goPage = id => {
    const { tab, location, face } = this.props;
    LM_DB.add('parameter', {
      list: this.state.list,
      searchData: {
        ...Utils.faceOptions(face.searchData),
        timerTabsActive: face.searchData.timerTabsActive
      },
      url: this.state.url,
      id,
      type: 'face'
    }).then(() => {
      tab.goPage({
        moduleName: 'resourceSearchDetail',
        location,
        data: { id }
      });
    });
  };

  closeModal = () => {
    this.setState({
      imgVisible: false
    });
  };

  /**
   * @desc 以图搜图多个特征值点击框选搜图
   * @param {Bollean} type 框选模态框显隐
   * @param {string} url 图片Url
   * @param {rect} 结构化信息
   */
  handFrame = (url, rects, type, option, imageUrl) => {
    // 新版图库 重新上传图片 删除aids 以及右侧aids列表
    if(this.state.url !== url) {
       this.setState({ aidImageList: [] });
       this.mergeSearchData({ aids: undefined }, false);
    }
    const id = Utils.uuid();
    let indexDBObj = null; // indexDB存储数据
    delete this.indexDBResult.expireTime;
    delete this.indexDBResult.userId;
    delete this.indexDBResult.time;
    indexDBObj = Object.assign({}, this.indexDBResult, { searchData: toJS(this.props.face.searchData), url, id });
    let pageData = Object.assign({}, this.urlParams, { id, isSearch: true, searchType: 1 }); // 跳转url参数
    this.changeIndexDBData(indexDBObj, pageData);
    if (option) {
      this.setState({
        frameUrl: imageUrl,
        rects: rects
      });
      this.onClickDefaultRect({ ...option, imgId: undefined }, url);
    } else {
      this.setState({
        url: url,
        frameUrl: imageUrl,
        rects: rects,
        imgVisible: type,
        modalkey: Math.random()
      });
    }
  };

  /**
   * @desc 以图搜图多个特征值点击默认框搜图
   */
  onClickDefaultRect = (parms = {}, url) => {
    const { searchData } = this.props.face;
    const { frameUrl } = this.state;
    const option = Object.assign({}, searchData, { feature: parms.feature, id: parms.smId });
    this.mergeSearchData(option, true, true);
    this.setState({
      url,
      imgVisible: false,
      frameUrl
    });
  };

  render() {
    const { list, loading, size, url, key, checkedIds, cKey, imgVisible, rects = [], frameUrl, modalkey, aidImageList } = this.state;
    const { searchData } = this.props.face;
    const isSearch = this.urlParams.isSearch || false;
    let listDataIds = [];
    list.map(v => {
      if (v.latitude && v.longitude) {
        listDataIds.push(v.id);
      }
      return v;
    });
    return (
      <Loading loading={loading} wrapperClassName="baselib-spining">
        <Wrapper className="face-wrapper face-new-wrapper" reset={this.reset}>
          <Search
            mergeSearchData={(values, needSearch) => this.mergeSearchData(values, needSearch, true)}
            searchData={searchData}
            url={url}
            frameUrl={frameUrl}
            key={key}
            isSearch={isSearch}
            changeUrl={this.changeUrl}
            handFrame={this.handFrame}
            rects={rects}
            aidImageList={aidImageList}
          />
          <Content
            list={list}
            limit={searchData.limit}
            wrappedComponentRef={refContent => (this.refContent = refContent)}
            onChecked={this.onChecked}
            loadMore={this.loadMore}
            size={size}
            searchData={searchData}
            isSearch={isSearch}
            key={cKey}
            type="face"
            checkedIds={checkedIds}
            goPage={this.goPage}
            initTime={this.initTime}
          />
          <div className="header-little-pagtion">
            <TitleOptions onChange={this.changesize} value={size} Refresh={this.Refresh} />
            {isSearch && (
              <RightHeader type="face" list={list} suffix={this.suffix} listDataIds={listDataIds} onChecked={this.onChecked} checkedIds={checkedIds} />
            )}
          </div>
        </Wrapper>
        <SearchMapModal
          className="img-cut-modal"
          visible={imgVisible}
          width="960px"
          title="以图搜图"
          // height="540px"
          url={frameUrl}
          // key={modalkey}
          rects={rects}
          type="face"
          otherModalFooter={true}
          onCancel={this.closeModal}
          onClickDefaultRect={this.onClickDefaultRect}
          onClickSelectRect={this.onClickSelectRect}
        />
      </Loading>
    );
  }
}

export default FaceLibrary;
