import React, { Component } from 'react';
import { Checkbox, Progress } from 'antd';
import '../index.less';

const ImageView = Loader.loadBaseComponent('ImageView');

class ImageList extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    choseList: [],
  }

  

  componentWillReceiveProps(nextProps) {
    this.setState({
      choseList: nextProps.searchData.aids || []
    })
  }

  changeBox = (data) => {
    const { change } = this.props;
    this.setState({ choseList: data });
    change && change({aids: data});
  }
  
  render() {
    const { choseList = [] } = this.state;
    const { data = [] } = this.props;
    return (
      <div className='image-list'>
        <Checkbox.Group onChange={this.changeBox} value={choseList}>
          {data.map(item => (
            <div className={`list-image ${choseList.find(v => v.aid === item.aid) ? 'active': ''}`}>
                <Checkbox value={item.aid}/>
                <ImageView src={item.url} className='image' hasErrorImageStyle={false}/>
                <div className='list-progress'>
                <Progress percent={item.score ? (~~item.score) : 0} size="small" status="active" showInfo={false} strokeWidth={2} />
                  <span style={{ fontSize: '12px' }} className="highlight">
                    {item.score ? (~~item.score) : 0}%
                  </span>              
                </div>
            </div>
          ))}
        </Checkbox.Group>
      </div>
    )
  }
}

export default ImageList;
