import React from "react";
import ImageList from "./imageList";

const Timer = Loader.loadBusinessComponent("BaseLibComponents", "Timer");
const SearchButton = Loader.loadBusinessComponent("BaseLibComponents", "SearchButton");
const PointSelect = Loader.loadBusinessComponent("BaseLibComponents", "PointSelect");
const RadioGroup = Loader.loadBusinessComponent("BaseLibComponents", "RadioGroup");
const CheckGroup = Loader.loadBusinessComponent("BaseLibComponents", "CheckGroup");
const ScoreSlider = Loader.loadBusinessComponent("BaseLibComponents", "ScoreSlider");
const BodyCharacteristics = Loader.loadBusinessComponent("BaseLibComponents", "BodyCharacteristics");
let { sex, eyeGlass, bigDatePlaceType, generation, faceMask, hat, head } = Dict.map;
export default class Search extends React.Component {
  constructor(props) {
    super(props);
    // this.sexArray = [].concat(sex, [{
    //   value: "other",
    //   label: "其他"
    // }]);
    this.sexArray = sex;
    this.generationArray = [].concat(generation, [
      {
        value: "other",
        label: "其他"
      }
    ]);
  }
  /**
   * @desc 编辑查询条件
   */
  change = (options = {}, needSearch = true) => {
    this.props.mergeSearchData(options, needSearch);
  };

  /**
   * @desc 切换时间Tabs
   */
  render() {
    const { searchData = {}, url = "", isSearch, handFrame, frameUrl, rects, aidImageList, changeUrl } = this.props;
    return (
      <>
        <div className="baselib-search-wrapper">
          <SearchButton type="face" url={url} change={this.change} changeUrl={changeUrl} handFrame={handFrame} frameUrl={frameUrl} rects={rects} />
          {isSearch && <ScoreSlider value={searchData.score} change={this.change} />}
          <div className="small-title">图库筛选 :</div>
          <Timer value={searchData.timerTabsActive} change={this.change} onOk={this.change} startTime={searchData.startTime} endTime={searchData.endTime} needSearch={true}/>
          <PointSelect onChange={this.change} selectList={searchData.cameraIds} />
          {/* {!isSearch && (
            <>
              <RadioGroup data={this.sexArray} label="性别" iconFont="icon-S_AID_Sex" value={searchData.sex} name="sex" change={this.change} />
              <RadioGroup data={this.generationArray} label="年龄段" iconFont="icon-S_AID_Age" value={searchData.generation} name="generation" change={this.change} />
              <RadioGroup data={head} label="头部特征" value={searchData.hat} iconFont="icon-S_AID_SunGlasses" name="hat" change={this.change} />
              <BodyCharacteristics
                label="上身特征"
                iconFont="icon-S_Photo_Theme"
                valueUpper={searchData.upColor}
                nameUpper="upColor"
                valueStyle={searchData.upStyle}
                change={this.change}
                nameStyle="upStyle"
                activeTabId={"sstz" + Math.random()}
              />
              <CheckGroup data={bigDatePlaceType} label="所在区域类型" value={searchData.placeType} iconFont="icon-S_Place_Community" name="placeType" change={this.change} />
            </>
          )} */}
        </div>
        <ImageList data={aidImageList} change={this.change} searchData={searchData}/>
      </>
    );
  }
}
